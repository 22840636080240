import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Smashin' Good Time",
    pageDescription:
      "Learn how an axe-throwing bar uses the SpotOn Restaurant POS system to increase sales with a comprehensive loyalty program.",
    title: "Loyalty that's right on target",
    subTitle: `How Smashin' Good Time keeps guests coming back with SpotOn Loyalty.`,
    businessName: "Smashin' Good Time",
    industry: "Restaurants & hospitality, brewery & bars",
    location: "Sparks, Nevada",
    products: [
      spotonProducts.RESTAURANT,
      spotonProducts.LOYALTY,
    ],
    description:
      "Jay and Ardith Peper started their axe-throwing and smash bar—Smashin' Good Time—when the stress of Jay's 9 to 5 job was causing him health problems. With SpotOn, they found a tech partner to help manage orders and increase sales through repeat business.",
    quoteData: {
      imgName: "smashin-good-time.png",
      quote: `“My account executive is amazing. What I want as a business owner is someone to hold my hand when I’m not competent in something. With so much on the line, I want to know things are going to work. To this day, I send her a quick email when I need something changed, and she takes care of it.”`,
      personName: "Ardith Peper",
      personTitle: "Owner and Manager, Smashin’ Good Time",
    },
    goal: `The Pepers were looking for a POS system to handle all of their food and beverages orders, capture their guests' information and ordering data, and provide an easy-to-use loyalty program to keep guests coming back to their tension-relieving bar.`,
    solution: `SpotOn Loyalty, integrated with SpotOn Restaurant, allows the Pepers to enroll guests into the Smashin' Good Time loyalty program automatically. And through the SpotOn Dashboard, they can market to their 2,000+ email list and access detailed reports.`,
    solutionReadMore: {
        text: `Read more about their story.`,
        url: `https://spoton.com/blog/smashin-good-time-peper-family/`,
      },
    results: {
      title: `The results`,
      stats: [
        { title: `2-minute`, description: `faster guest check-in process` },
        {
          title: `8%`,
          description: `increase in sales through loyalty`,
        },
        { title: `5 hours`, description: `saved on weekly admin tasks` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Boosting traffic to a BBQ business",
        imageName: "chicago-culinary-kitchen.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-02",
        href: "/case-studies/chicago-culinary-kitchen",
        goals: [
          resourceGoals.REACH_NEW_CUSTOMERS,
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Selling more slices of pizza",
        imageName: "tonys-pizza.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-02-24",
        href: "/case-studies/tonys-pizza",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.REACH_NEW_CUSTOMERS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
    {
        title: "Coordinating a Caribbean restaurant",
        imageName: "freetown-road-project.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-28",
        href: "/case-studies/freetown-road-project",
        goals: [
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
  ];
  